import { useEffect } from "react";

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'CONTACT US';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Get in touch with the Monkey Mart team! Whether you have questions, feedback, or need support, our team is here to help. Contact us now and let's connect!");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Monkey Mart - Play Monkey Mart Game Online Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play Monkey Mart Game unblocked at school or work! Discover the best collection of unblocked games, perfect for quick breaks and endless fun anytime, anywhere!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://monkeymart.games/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">Contact Us - Monkey Mart Game</h1>
                    <br />
                    <span class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Welcome to Monkey Mart, the ultimate destination for fans of interactive and engaging gaming experiences! Our platform brings the colorful world of Monkey Mart to life, providing players with resources, guides, tips, and a dedicated space to connect with fellow fans.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Our Mission
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        At Monkey Mart, our mission is to create a vibrant community where players of all ages can discover, enjoy, and master the art of managing their very own digital store. We believe in fostering a supportive and informative environment where users can find everything they need to maximize their gaming experience.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        What We Offer
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Whether you're a newcomer eager to learn or a seasoned player looking for the latest updates, Monkey Mart is here to help. Here’s what we bring to the table:
                    </div>
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Game Guides: Step-by-step tutorials and strategies to help you level up your gameplay and achieve success in Monkey Mart.
                            </li>
                            <li>
                                Latest Updates: Stay informed about the newest features, events, and updates in Monkey Mart.
                            </li>
                            <li>
                                Community Hub: Join our community of passionate players to exchange tips, share experiences, and enjoy a truly interactive gaming experience.
                            </li>
                            <li>
                                Exclusive Content: Access insider tips, unique game strategies, and hidden secrets that will elevate your gameplay to the next level.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How Reach Us
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Email: <a href="mailto:support@monkeymart.games" className=" text-blue-500">{`support@monkeymart.games`}</a>
                            </li>
                            <li>
                                Social Media: Connect with us on Facebook, Twitter, and Instagram for updates and community news.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}