import { useEffect } from "react";

export default function About() {
    useEffect(() => {
        console.log("windowwindow---- ", window.location.href)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'About Us | Monkey Mart';
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Discover the exciting world of Monkey Mart - a unique store management game featuring adorable monkeys. Build and manage your own supermarket, serve customers, and become a wealthy monkey boss!');
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Monkey Mart - Play Monkey Mart Game Online Unblocked!';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play Monkey Mart Game unblocked at school or work! Discover the best collection of unblocked games, perfect for quick breaks and endless fun anytime, anywhere!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://monkeymart.games/');
            }
        };
    }, []);
    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="container 2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">About Us</h1>
                    <br />
                    <span class="des-game 2xl:text-lg lg:text-lg md:text-lg text-base flex !text-justify">
                        Welcome to Monkey Mart, an exciting idle supermarket management game where you play as a cheerful monkey managing a bustling market. In this game, you’ll experience the thrill of building and running your own store, from planting fruits and vegetables to arranging products on shelves and ringing up customers. Monkey Mart combines easy-to-learn gameplay with strategic depth, perfect for both casual players and dedicated fans of management games.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Why Monkey Mart Stands Out in Idle Games
                    </h2>
                    <br />
                    <div class="des-game flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Monkey Mart offers a unique blend of idle gameplay and supermarket management, setting it apart from other games. Here’s what makes it a standout experience:
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Start Small, Grow Big: Build and Manage Your Supermarket
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        In Monkey Mart, you begin with just a small market and a few basic items, like bananas and corn. As you harvest produce and keep your shelves stocked, you’ll attract more customers and earn profits. Move from station to station, planting crops, harvesting produce, and keeping the shelves full of fresh items. As you master the basics, you’ll unlock more food items to increase your product variety, like eggs, peanuts, coffee beans, chocolate, and wheat.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Expand Your Store with New Aisles and Diverse Products
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Unlocking new aisles is key to growing your market. With each expansion, you can introduce new sections and products that bring in different types of customers. Offer dairy items like yogurt, create a snack section with popcorn and cookies, or add a café corner with freshly brewed coffee. Every new aisle adds to the shopping experience, drawing more customers and increasing your earnings.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Hire Helpers and Train Your Team for Efficiency
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Managing a large supermarket alone can be challenging, but you don’t have to do it all by yourself! Monkey Mart allows you to hire assistants who help you keep the market running smoothly. Assign them to different sections so they can handle tasks like restocking shelves or ringing up customers. As you progress, you can even train your team to work faster and more efficiently, ensuring your market keeps up with customer demand.
                    </div>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. Personalize Your Monkey with Cool Hats and Accessories
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Bring a personal touch to your store management by customizing your character. Choose from a variety of hats and accessories that make your monkey stand out, adding a dash of fun and personality to the game. Whether it’s a chef’s hat, a straw hat, or something quirky, dressing up your monkey adds charm to the gameplay.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Boost Profits with Advanced Appliances and Specialty Products
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        As you earn more, you’ll be able to invest in advanced appliances that allow you to turn raw ingredients into premium products. Make chocolate bars, peanut butter, popcorn, muffins, and even ice cream! These specialty items not only increase customer satisfaction but also boost your revenue significantly. By adding premium items, you can cater to a broader audience, making your supermarket a favorite stop in town.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Key Features of Monkey Mart - An Idle Game Like No Other
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Monkey Mart isn’t just about stocking shelves—it’s about strategy, growth, and creativity. Here are some of the standout features that make it a must-play:
                    </div>
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Idle Gameplay: Earn income even when you’re offline. Just set up your stations, stock your shelves, and let your market thrive around the clock.
                            </li>
                            <li>
                                Vibrant Graphics and Animation: Enjoy a visually engaging environment with colorful graphics, lively animations, and a cheerful atmosphere that brings every task to life.
                            </li>
                            <li>
                                Engaging Challenges and Levels: Each level introduces new products, customer needs, and tasks. You’ll encounter fun challenges that keep the game interesting and rewarding.
                            </li>
                            <li>
                                Freedom to Customize and Upgrade: With so many ways to expand and improve, you have the freedom to shape your supermarket. Upgrade your monkey’s skills, improve appliances, and unlock new aisles as you progress.
                            </li>
                            <li>
                                Educational for Kids and Fun for All Ages: Monkey Mart is easy to learn and intuitive to play, making it suitable for all ages. It’s a fun way for kids to learn about running a store, while older players enjoy the strategy and depth of management.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How to Make Your Supermarket a Success in Monkey Mart
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Want to be the best market manager? Here are a few strategies to grow your store:
                    </div>
                    <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Keep Your Shelves Stocked: Customers love a fully stocked market, so make sure to keep popular items readily available.
                            </li>
                            <li>
                                Expand Wisely: Unlock new aisles as soon as you have the funds, and focus on adding products that attract more customers.
                            </li>
                            <li>
                                Hire and Train Helpers: Efficient staff is key to handling a busy market. Train your helpers to work faster and manage different areas effectively.
                            </li>
                            <li>
                                Upgrade Appliances: Investing in advanced equipment allows you to create specialty products, increasing both customer satisfaction and revenue.
                            </li>
                            <li>
                                Track Profits and Manage Inventory: Always keep an eye on which products sell well, and manage your stock to avoid shortages.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Ready to Build Your Supermarket Empire?
                    </h2>
                    <br />
                    <div class="des-game  flex !text-justify  2xl:text-lg lg:text-lg md:text-lg text-base">
                        If you love management and idle games, Monkey Mart offers a unique, engaging experience that combines the best of both worlds. Test your skills, grow your store, and build a supermarket that becomes the town’s top shopping destination. From the thrill of expansion to the satisfaction of seeing customers happy, Monkey Mart offers endless hours of fun. Try it now and discover your inner manager in Monkey Mart – the ultimate supermarket management game!
                    </div>
                </div>
            </div>
        </div>
    )
}